<script setup lang="ts">
import UserService from '~/services/MSAPI/user'
import BalanceService from '~/services/MSAPI/balance'
import type { EPlanType } from '~/constants/organizationPlan'
import type { NGetOrganizationsByUserId } from '~/services/MSAPI/user/user/types'

const userService = new UserService()
const balanceService = new BalanceService()
const authStore = useAuthStore()

interface ISelectOption {
  label: string
  value: string
  selected: boolean
}

const route = useRoute()

const organizationListQueryParams = {
  includeDescendentOrganizations: true,
  page: 1,
  count: 99999,
} as NGetOrganizationsByUserId.IParams

const { data: organizationListData, isFetching: isFetchingOrganizationData } =
  useQuery({
    queryKey: ['organizationListHeader'],
    queryFn: () =>
      userService.user.getOrganizationsByUserId(
        authStore.userData?.userId as string,
        organizationListQueryParams,
      ),
    enabled: () =>
      Boolean(route.params.organizationId) &&
      Boolean(authStore.userData?.userId),
  })

const organizationOptions = computed<ISelectOption[]>(() => {
  const mappedOptions = organizationListData.value?.data?.items.map(
    ({ organizationId, companyName }: NGetOrganizationsByUserId.IItem) => {
      return {
        value: organizationId,
        label: companyName,
        selected: organizationId === route.params.organizationId,
      }
    },
  )

  return mappedOptions || []
})

const isDisabled = computed(
  () =>
    isFetchingOrganizationData.value || organizationOptions.value.length === 0,
)

const selectOrganization = async (option: ISelectOption[]) => {
  const selectedOption = option[0]
  try {
    const session = await userService.user.getSession(selectedOption.value)
    const planRequest = await balanceService.organizationPlan.get(
      selectedOption.value,
    )

    authStore.setUserSession(session.data.user)
    authStore.setOrganizationSession(session.data.organization)
    authStore.setOrganizationPlanSession(
      planRequest.data.plan?.name as string,
      planRequest.data.plan?.planType as EPlanType,
    )
  } catch (e) {
    console.error('error', e)
  }

  await navigateTo({
    // name: route.name || 'organizationId-dashboard', // Por definição, ao selecionar uma organização deve ser direcionado para o dashboard
    name: 'organizationId-dashboard',
    params: { organizationId: selectedOption.value },
  })
}
</script>

<template>
  <div>
    <span
      class="tw-block tw-uppercase tw-text-sm tw-font-medium tw-text-gray-400 tw-mb-1"
      >{{
        $t('organization.subOrganization.subOrganizationList.organization')
      }}</span
    >
    <CMultiSelect
      :multiple="false"
      :options="organizationOptions"
      :loading="isFetchingOrganizationData"
      :placeholder="$t('organization.dropdown.select.placeholder')"
      :disabled="isDisabled"
      virtual-scroller
      @change="selectOrganization"
    />
  </div>
</template>
